.button-white {
    background-color: #FFF !important;
}

button.button-white.dark-panel.btn.btn-outline.active {
    border-color: #ffc20e !important;
    border: solid #ffc20e !important;
    border-width: 0.4rem !important;
}

#hero-panel {
    background-color: var(--white);
    color: var(--slate);
    text-align: center;
}

#hero-panel .hero-content {
    height: 100%;
}

#hero-panel .work-order-content {
    background-color: var(--smoke);
    color: var(--secondary-dark);
}

.hero-slider {
    z-index: 3000;
    margin-top: 3.0rem;
}

.profile-slider {
    padding-left: 5.4rem !important;
    background-color: var(--smoke) !important;
}

.seller-decoration, .inspector-decoration {
    border-top: 1px solid #babcbe;
}

.seller-overlay-box, .inspector-overlay-box {
    width: 120px;
    height: 15px;
    display: block;
    background-color: var(--white);
    margin: auto;
    top: -10px;
    position: relative;
}

.seller, .inspector {
    margin-top: -25px;
    position: relative;
    z-index: 1;
}

.photo-panel-back-button {
    font-size: 1.8rem;
    vertical-align: bottom;
    color: white;
}