.veh-info-header {
    color: #FFF;
    height: 30px;
    align-content: center;
    background-color: var(--secondary-dark);
    z-index: 3500;
    width: 100%;
}

.veh-info-header .ymmt {
    border-left: 1px solid #FFF;
    padding-left: 10px;
}

.veh-info-header .work-order {
    margin: 0 10px;
}

.veh-info-header-triangle {
    font-size: 20px;
    vertical-align: middle;
    top: -1px;
    position: relative;
}