.rbt-input-main {
    background-color: transparent;
    border: 1px solid #babcbe !important;
}

.rbt > div {
    height: unset !important;
}

.hide-list > ul {
    display: none !important;
}

.rbt-aux {
    display: none;
}

.damage-modal .modal-content {
    position: relative;
    left: -80px;
    top: -75px;
    padding: 16px 8px 8px 8px;
}

.damage-modal-header {
    z-index: 500;
}

.disabled {
    background-color: var(--ash);
}

.take-photo {
    padding: 0 0 2px 0 !important;
    height: 64px;
}

.retake-photo {
    height: 64px;
    margin-bottom: 6px;
}

.take-photo i {
    font-size: 50px !important;
}

.retake-photo i {
    font-size: 30px;
    margin-bottom: 4px;
}

.required-photo {
    margin-top: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.required-photo-icon {
    font-size: 25px;
    margin-right: 16px;
}

.required-photo-provided {
    color: var(--success);
}

.required-photo-needed {
    color: var(--danger);
}

.delete-photo-button {
    background-color: var(--red) !important;
    color: var(--white) !important;
    height: 64px;
}

.delete-photo-button:disabled {
    background-color: var(--smoke) !important;
    color: var(--charcoal) !important;
    height: 64px;
}

.delete-photo-button i {
    font-size: 30px;
    margin-bottom: 4px;
}

#damage-wrapper .multimediaviewer-wrapper {
    width: 80%;
    margin: auto;
}

.floating-message {
    position: fixed !important;
    top: 5px;
    width: 80%;
    z-index: 7000;
}

#add-damage-item-list .dropdown-item {
    color: var(--charcoal)
}
