#work-list .row {
    /*padding-bottom: 0.5em;*/
    /*padding-top: 0.5em;*/
    /*border-bottom: 1px solid var(--navy);*/
}

#work-list .section-name {
    padding-top: 0.4em;
}

#work-list button {
    float: right;
}

#work-list .row .work-list-item-status {
    float: right;
}