.notes-container-group, .notes-row {
    border-bottom: var(--smoke) 1px solid;
}

.notes-subheader {
    margin-bottom: 1rem;
}

.delete-note-button {
    background-color: var(--red) !important;
    color: var(--white) !important;
}


#comments-panel {
    background: #ffffff !important;
}