.panel--outline {
    border: 1px solid var(--smoke);
    border-radius: 5px;
    padding: 0 8px;
    margin: 0 0 8px 0;
}

.panel-head {
    background-color: #c1dff2;
    margin: -8px -8px 0 -8px;
    padding: 8px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid var(--smoke);
}

.panel-head-damage {
    background-color: var(--secondary-dark);
    margin: -8px -8px 0 -8px;
    padding: 8px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid var(--smoke);
    text-align: center;
    color: #fff;
}

.panel-head-add-damage {
    height: 56px;
    background-color: var(--smoke);
    border: 1px solid var(--smoke);
    margin: -8px -9px 0 -9px;
}

.panel-column {
    border-right: 1px solid var(--smoke);
    margin-top: -5px;
    margin-bottom: -5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.panel-column:last-child {
    border-right: 0px solid var(--smoke);
}

.panel-table, .review-table{
    width: 100%;
}

.panel-table thead {
    background-color: #c1dff2;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #babcbe;
}

.review-table thead {
    background-color: #babcbe;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #6a6a6a;
    color: #000;
}

.panel-table th, td {
    height: 50px;
    padding: 4px 10px;
}

.review-table th, td {
    height: 40px;
    padding: 4px 10px;
}

.panel-table th:first-of-type, .review-table th:first-of-type {
    border-radius: 4px 0 0 0;
}

.panel-table th:last-of-type, .review-table th:last-of-type {
    border-radius: 0 4px 0 0;
}

.panel-table td, .review-table td {
    font-size: 1.4rem;
    font-weight: 300 !important;
}

.panel-table tr:nth-of-type(even), .review-table tr:nth-of-type(even) {
    background-color: #eeeeee;
}


