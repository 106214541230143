#edit-remarks-content .sticky-top {
    background-color: var(--navy-dark);
    padding-top: 1em;
}

#edit-remarks-content .sticky-top .no-top-margin {
    margin-top: 0px !important;
}

.f5-notes-container {
    border-bottom: 1px solid #FFFFFF;
    margin-bottom: 2rem;
}

.f5-notes-container:first-of-type {
    margin-top: 16px;
}