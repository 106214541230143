.nav-item {
    height: 70px;
}

.actions-action.tab.actions-action {
    background-color: var(--yellow);
    color: var(--white);
}

/* top-level tab layout */
.actions-action.tab {
    background-color: var(--blue);
    color: var(--white);
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    font-size: 1rem;
}

.tabs.tabs-light .tab-bar {
    background-color: var(--blue);
}

.tabs.tabs-dark .tab-bar {
    background-color: var(--blue);
}

.actions-action.btn.tab.active {
    background-color: var(--yellow);
    color: var(--blue);
}

.actions-action.btn.tab {
    background-color: var(--blue);
    color: var(--white);
    height: 100%
}

.tabs .nav-tabs{
    margin-left: 5%;
    min-width: 105%;
    justify-content: center;
}

.tab > span.actions-label {
    top: 45px;
    position: absolute;
}

/* If tabs are added/subtracted, adjust width here */
.tabs .nav-tabs .nav-item{
    margin: 0;
    width: 20%;
}

.tabs .nav-tabs button{
    margin: 0;
    min-width: 100%;
}

.tabs .tab-bar.has-border:after {
    width: 0;
}

/* Icon size */
.tab:before {
    height: 30px !important;
    display: inline-block;
    content: '';
    mask-size: cover;
    top: 8px;
    margin-top: 4px;
}

.tabs .tab:before {
    background: var(--white);
}

.tabs .tab.disabled::before {
    background: var(--white) !important;
    content: '' !important;
}

.tabs .tab:hover::before {
    background: var(--white);
}

.tab:before {
    background: var(--white);
}

.tabs .tab.active:hover::before {
    background: var(--blue) !important;
}

.tab.active:before {
    background: var(--blue) !important;
}

.actions-action.btn.tab.disabled {
    background-color: var(--smoke) !important;
}

.configure-tab:before{
    mask: url("Configure.svg") no-repeat 50% 50%
}

.tires-tab:before {
    mask: url("Tire.svg") no-repeat 50% 50%;
}

.accessories-tab:before {
    mask: url("Accessories.svg") no-repeat 50% 50%;
}

.damage-tab:before {
    mask: url("damages.svg") no-repeat 50% 50%;
}

.condition-tab:before {
    mask: url("Condition.svg") no-repeat 50% 50%;
}

.review-tab:before {
    mask: url("Review.svg") no-repeat 50% 50%;
}