/* added for mobile view */
.rotate {
    transform-box: fill-box;
    transform-origin: center;
    transform: rotate(-90deg);
}

/*unselected panel, no/addressed suggested damages*/
.flat-car .panel-dark {
    fill: var(--charcoal);
}

.flat-car .panel-white {
    fill: var(--white);
}

.flat-car .panel-op-25 {
    opacity:0.25;
}

.flat-car .panel-outline {
    stroke-width: 0.5px;
    stroke: var(--white);
}

/*selected panel, no/addressed suggested damages*/
.flat-car .active {
    fill:var(--gold)
}

/*selected panel, has unaddressed suggested damages*/
.flat-car .active-suggested {
    fill: url(#active-suggested);
}

/*unselected panel, has unaddressed suggested damages*/
.flat-car .inactive-suggested {
    fill: url(#inactive-suggested);
}

.full-width {
    width: 100% !important;
}

svg {
    pointer-events: none;
}

path {
    pointer-events: auto;
}

polygon {
    pointer-events: auto;
}

ellipse {
    pointer-events: auto;
}

#flat-car-item-list {
    transform: translate3d(0px, 36px, 0px) !important;
}

#flat-car-item-list::-webkit-scrollbar {
    display: unset;
}

#flat-car-item-list::-webkit-scrollbar-track {
    border: 1px solid #babcbe;
    background-color: #EEE;
}

#flat-car-item-list::-webkit-scrollbar-thumb {
    background-color: #003468;
    border-radius: 10px;
}

#flat-car-item-list .dropdown-item {
    color: var(--charcoal)
}

#flat-car-input-container input:disabled{
    background-color: #FFFFFF;
    border: 0 !important;
}