.text-white {
  color: #FFF !important;
}

.text-smoke {
  color: var(--smoke);
}

.text-primary {
  color: var(--primary);
}

.test{
    margin: 0;
}

.form-control {
  padding-bottom: 0.8rem !important;
  padding-top: 0.8rem !important;
}

.add-remove-key-button {
  position: absolute;
  top: -.8rem;
  right: 0px;
  display: block;
}

.bg-ash {
  background-color: var(--ash);
}

.tire-size-input {
  background-color: #fff !important;
  border: 1px solid var(--smoke) !important;
  padding-left: 1.2rem !important;
}

#summary-header {
  height: 100%;
}

.damage-summary-row {
    border-top: 3px solid var(--smoke);
}

.damage-summary-header {
  background-color: var(--smoke);
}

.damage-summary-header label {
  margin-bottom: 2px;
  margin-top: 2px;
}

.damage-summary-col {
    text-align: center;
  border-right: 1px solid var(--smoke);
  padding: 10px;
}

.damage-summary-col:last-child {
  border-right: 0;
}

.suggested-damage-buttons {
  padding: 0px;
}

.damage-summary-buttons {
  padding: 0px;
  border-top: 1px solid var(--smoke);
}

.damage-summary-buttons .central,
.suggested-damage-buttons .central {
  border-left: 1px solid var(--smoke);
  border-right: 1px solid var(--smoke);
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.damage-summary-buttons .left,
.suggested-damage-buttons .left {
  text-align: center;
}

.damage-summary-buttons .right,
.suggested-damage-buttons .right {
  text-align: center;
}

.damage-severity-container {
    border-bottom: 1px solid var(--smoke)
}

.suggested-damage-image {
    max-width: 90%;
    margin: 10px 0 10px 0;
    border: 3px solid var(--charcoal);
}

.suggested-damage-image-container {
    text-align: center;
    height: 100%;
}

.right-align {
  text-align: right;
}

.align-center {
  text-align: center;
}

.margin-auto {
  margin: auto;
}

.image-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 35vh
}

@media only screen and (max-height: 700px){
    .image-overlay {
        top: 25vh
    }
    .modal-content {
        padding-bottom: 0.5rem !important
    }

    .multimediaviewer{
        margin: 0rem !important
    }
}

.announcement-list-container {
    height: 1050px;
    display: flex;
    flex-direction: column;
}

.announcement-list-container>* {
    /*flex: 0 1 33.33%!important;*/
    flex:100%;
}

.announcement-list-container .itemContent{
    align-items: flex-start;
    margin-bottom: 7px !important;
    margin-top: 7px !important;
}

.btn-full-width {
    margin: 0 !important;
    width: 100%;
}

.btn-width-80 {
    width: 80%;
}

#last-service-date {
    background-color: #fff;
    border: 1px solid var(--smoke) !important;
    padding-left: 1.2rem;
}

.large-button-icon {
    font-size: 1.8rem;
}

#auction-list input {
    border: 0 !important;
}

#scanner_work_order_number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#scanner_work_order_number[type=number] {
    -moz-appearance: textfield;
}

#scanner_work_order_number {
    padding-left: 36px !important;
}

#header-container {
    padding-right: 0 !important;
}

.accordion-trigger-label {
    flex-grow: 1;
}

.accordion-item__wrapper {
    width: 100%;
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0s;
    -o-transition: transform 0s;
    transition: transform 0s, -webkit-transform 0s;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none; }

.logout-button-icon {
    color: white;
}

#maintenanceBook + .checkbox-label {
    text-align: left;
}

.bg-cerulean-light {
    background-color: #c1dff2;
}

.btn-edit-bug {
    background-color: #eeeeee !important;
}

.input-group-prepend .btn,
.input-group-append .btn {
    z-index: unset !important;}

.black-circle-background .icon,
.gray-circle-background .icon {
    border-radius: 50%;
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.black-circle-background .icon {
    background-color: black;
}

.gray-circle-background .icon {
    background-color: #888;
}

.text-white {
    color: white;
}

.text-light-gray {
    color: #ccc;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}