.tabs.tabs-light .tab-bar {
    background-color: var(--blue);
}

.tabs .tab-bar {
    overflow-x: auto;
    overflow-y: visible;
    white-space: nowrap;
    scroll-behavior: smooth;
    justify-content: center;
    display: flex;
}

.tabs .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    border: none;
}
.nav-tabs {
    border-bottom: 1px solid #babcbe;
}
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.tab-content {
    margin: 1.6rem;
}

.tab-content:not(.react-tabs__tab-panel--selected) {
    display: none;
}

.tab-content.react-tabs__tab-panel--selected {
    margin-top: 20px;
}

.tabs.tab-light-bkg .tab-content {
    padding: unset;
    overflow: unset;
}

.condition-tab-bar {
    top: 30px;
}