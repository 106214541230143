@keyframes spinner {
    to {transform: rotate(360deg);}
}

.spinner-icon {
    color: #003468 !important;
}

.spinner {
    animation: spinner 1.0s linear infinite;
    margin: auto;
}

.spinner-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color:rgba(255,255,255,0.7);
    z-index: 5000;
    text-align: center;
    flex-wrap: wrap;
}

.spinner-container {
    flex-grow: 1;
    flex-basis: 100%;
}

.spinner-container-with-button {
    flex-grow: 1;
    flex-basis: 100%;
    margin-top:auto;
}

.cancel-button-container {
    flex-grow: 0;
    flex-basis: 100%;
    margin-top: 30px;
    margin-bottom:auto;
    margin-left:auto;
    margin-right:auto;
}