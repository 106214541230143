#footer-content {
    margin-bottom: 8px !important;
    margin-left: 35px !important;
    margin-top: 8px;
}

#modal-footer-row {
    margin-top: 8px;
    margin-bottom: 8px !important;
    padding-right: 60px;
}

#keep-photo-footer-content {
    margin-left: 50px !important;
}

#image-modal-footer-content {
    margin-bottom: 8px !important;
    margin-top: 8px;
    margin-left: 0px !important;
}

#error-modal-cancel-btn {
    margin-left: 36px;
}

#error-modal-continue-btn {
    margin-left: 38px;
}

#work-order-error-continue-btn {
    margin-left: 20px;
}

#work-order-error-ok-btn {
    margin-left: 60px;
}

#work-order-error-cancel-btn {
    margin-left: 50px;
}

#audit-exists-ok-btn {
    margin-left: 60px;
}

#redecode-continue-btn {
    margin-left: 40px;
}

#logout-continue-btn {
    margin-left: 10px;
}

#audit-exists-cancel-btn,
#close-inspection-cancel-btn,
#redecode-cancel-btn {
    margin-left: 50px;
}

#logout-cancel-btn{
    margin-left: 40px;
}

#modal-header-content {
    margin-left: 6px !important;
}

#locked-record-ok-btn {
    margin-left: 55px;
}

#modal-body-content,
#audit-exists-modal-body-content,
#keep-photo-modal-body-content,
#close-inspection-modal-body-content,
#work-order-error-modal-body-content,
#logout-modal-body-content,
#session-timeout-modal-body-content,
#redecode-modal-body-content {
    margin-top: 6px;
    margin-left: 6px;
}

#audit-exists-modal {
    z-index: 9700 !important;
}

#close-inspection-modal {
    z-index: 9800 !important;
}

#work-order-error-modal {
    z-index: 9600 !important;
}
