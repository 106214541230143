.work-list-item-status.icon {
    color: var(--gold);
}

.work-list-item-status.icon.status-failed {
    color: var(--scarlet);
}

.work-list-item-status.icon.status-invalid {
    color: var(--scarlet);
}

.work-list-item-status.icon.status-success {
    color: var(--cerulean);
}

.work-list-item-status-container {
    align-items: center;
    display: flex;
}