.section-container {
    border: 1px solid var(--smoke);
    padding-bottom: 1.6rem;
    margin-bottom: 2.4rem;
    border-radius: 4px;
}

.section-head {
    height: 40px;
    background-color: var(--smoke);
    color: #000;
    font-weight: bolder;
    font-size: 18px;
    align-items: center;
    display: flex;
    padding-left: 1.6rem;
}

.section-break {
    border-top: 1px solid var(--smoke);
    margin: 0 1.6rem 1.6rem 1.6rem;
}

.border-smoke {
    border-color: var(--smoke)
}

#damage-list-view .section-container {
    padding-bottom: 0;
}

.trim-finder-trim-row {
    padding: 12px 6px;
    border-bottom: 1px solid var(--smoke);
    color: grey;
}

.trim-finder-trim-row:last-of-type{
    padding: 12px 6px;
    border-bottom: none;
}

.text-black {
    color: black;
}