#profile-container {
    background-color: white;
    height: 100vh;
}

#profile-panel-main-header {
    background-color: #004986;
    padding-top: 20px;
    padding-bottom: 20px;
}

.user-profile-heading-row {
    background-color: #2c90cc;
}

.user-setting-header {
    background-color: var(--smoke);
}

.switch-label {
    color: black;
}

.user-profile-heading {
    color: white;
    font-size: 2rem;
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.profile-section-heading {
    color: black;
    font-size: 1.7rem;
    margin-left: 10px;
    margin-bottom: 2px;
}

.profile-section-text {
    color: #2c90cc;
    font-size: 1.6rem;
    margin-left: 10px;
    margin-bottom: 5px;
}

.logout-leave-icon {
    color: #FFFFFF !important;
}
