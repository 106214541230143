.work-order-number {
    cursor: pointer;
}

.work-list-item {
    padding-bottom: 0.5em;
    border-bottom: 1px solid var(--navy);
}

.work-list-item-header {
    padding-bottom: 0.5em;
    border-bottom: 1px solid var(--navy);
}