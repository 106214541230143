.image-set-button {
  /*width: 150px;*/
  /*background-color: var(--navy);*/
  color: var(--white);
  border-radius: 8px;
  background-color: var(--secondary);
  margin-bottom: 2.4rem;
}
.image-set-button .header {
  width: 100%;
  background-color: var(--cerulean);
  border-radius: 8px 8px 0 0;
  padding: 6px 10px
}

.image-set-button img {
  object-fit: cover;
  width: 100%;
  height: 250px;
  border-radius: 0 0 8px 8px;
}

.footer-decoration {
  transform: translate(-50%, -50%);
  height: 50px;
  width: 100px;
  border-radius: 150px 150px 0 0;
  background-color: white;
  border: 5px solid rgba(44, 144, 204, .3);
  border-bottom: none;
  position: absolute;
  bottom: 0;
  align-content: end;
  left: 50%;
}

.footer-camera-icon {
  color: var(--cerulean);
  z-index: 500;
  position: absolute;
  bottom: 32px;
  font-size: 30px;
}

.thumbnail-item {
  border: 1px solid var(--cerulean);
  background-color: var(--white);
  border-radius: 10px;
  text-align: center;
  width: 100%;
  flex-grow: 1;
  min-height: 100px;
}

.thumbnail-item-header {
  color: var(--black);
  font-size: 15px;
  text-align: left;
  margin: 3px 10px;
}

.thumbnail-item-footer {
  position: relative;
  background-color: var(--cerulean);
  color: var(--white);
  padding: 5px 8px;
  border-radius: 0 0 8px 8px;
}

.thumbnail-placeholder {
  color: var(--smoke);
  font-size: 75px;
  padding: 10px auto 20px auto;
}

.thumbnail-item img {
  object-fit: cover;
  width: 100%;
  height: 120px;
  border-radius: 8px 8px 0 0;
}

.imageviewer-photo-not-available {
  background-color: #4a4a4a;
  height: 200px;
  width: 100%;
  color: #ffc20e;
  font-size: 24px;
  justify-content: center;
}
