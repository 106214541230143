.hero-slider-footer {
    background: rgba(238, 238, 238, 0.95);
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 0.8rem 1.6rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-width: 0;
    display: flex;
    z-index: 5500;
}