
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.5rem 1.25rem;
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--black);
    text-align: left;
    background-color: var(--smoke);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
    .accordion-button {
        transition: none;
    }
}

.accordion-button:not(.collapsed)::after {
    transform: rotate(-180deg);
}
.accordion-button::after {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
    font-family: "prism-icon-font";
    content: "\EDBD";
    transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
        transition: none;
    }
}
.accordion-button:hover {
    z-index: 2;
}
.accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
}

.accordion-header {
    margin-bottom: 0;
}

.accordion-item {
    color: #000;
    background-color: var(--white);
}

.accordion-item:not(:first-of-type) {
    border-top: 0;
}

.accordion-body {
    padding: 1.5rem 1.6rem 0 1.6rem;
}

.accordion-flush .accordion-collapse {
    border-width: 0;
}

.accordion-flush .accordion-item:first-child {
    border-top: 0;
    border-radius: 4px

}
.accordion-flush .accordion-item:last-child {
    border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
    border-top: 4px solid var(--white);

}

.accordion-flush {
    width: 100%;
    margin: 0 1.6rem;
}