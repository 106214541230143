.review-row {
    border-bottom: 1px solid var(--smoke);
    font-size: 2.5rem;
    align-items: center;
}

.review-row-title {
    font-variant: small-caps;
    margin-bottom: 0
}

.review-list-rows {
    text-transform: capitalize;
    font-size: 20px;
    color: #a9a9a9;
    margin-bottom: 0
}

.status-icon {
    font-size: 25px;
    display:inline-block;
}

.show-is-clickable {
    cursor: pointer
}

.review-error-message {
    display: inline-block;
    border-color: #4a4a4a;
    color: #c33a00;
    font-family: Roboto,sans-serif;
    font-size: 14px;
    margin-top: .8rem;
}