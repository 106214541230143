.slide-pane {
    display: flex;
    flex-direction: column;
    background: #001b35;
    min-width: 100px;
    height: 100%;
    box-shadow: 0 8px 8px rgba(0,0,0,0.5);
    transition: transform 0.5s;
    will-change: transform;
}
.slide-pane:focus {
    outline-style: none;
}
.slide-pane_from_right {
    margin-left: 0px;
    transform: translateX(100%);
}
.slide-pane_from_right.ReactModal__Content--after-open {
    transform: translateX(0%);
}
.slide-pane_from_right.ReactModal__Content--before-close {
    transform: translateX(100%);
}
.slide-pane_from_left {
    margin-right: auto;
    transform: translateX(-100%);
}
.slide-pane_from_left.ReactModal__Content--after-open {
    transform: translateX(0%);
}
.slide-pane_from_left.ReactModal__Content--before-close {
    transform: translateX(-100%);
}
.slide-pane__overlay {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    background-color: rgba(0,0,0,0);
    z-index: 4000;
}
.slide-pane__overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0,0,0,0.3);
    transition: background-color 0.5s;
}
.slide-pane__overlay.ReactModal__Overlay--before-close {
    background-color: rgba(0,0,0,0);
}
.slide-pane__close {
    margin-right: 24px;
    padding: 16px;
    opacity: 0.7;
    cursor: pointer;
    color: #FFF;
    text-align: right;
    font-size: 2em;
}
.slide-pane__close svg {
    width: 12px;
    padding: 0;
}
.slide-pane__content {
    position: relative;
    overflow-y: auto;
    flex: 1 1 auto;
}
.offset-left {
    left: 100px
}